import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';
import Home from './Components/Home';
import Booking from './Components/Booking';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        {/* <Route path='/' element={<Booking/>}></Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
