import React, { useState } from 'react';
import { Form, Input, Button, Select, InputNumber } from 'antd';
import { PlusOutlined, MinusOutlined, CloseOutlined } from '@ant-design/icons';

const { Option } = Select;

const Booking = ({ onClose }) => {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        adults: 0,
        children: 0,
        event: ""
    });

    const handleChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        });
    };

    const handleAdultChange = (value) => {
        setFormData({
            ...formData,
            adults: value
        });
    };

    const handleChildrenChange = (value) => {
        setFormData({
            ...formData,
            children: value
        });
    };

    const handleSubmit = () => {
        // You can do something with the formData, like sending it to a server or storing it in state
        console.log(formData);
    };

    return (
        <div className='form-container'>
            <Form layout="vertical" onFinish={handleSubmit}>
                <Form.Item label="Name">
                    <Input
                        value={formData.name}
                        onChange={e => handleChange('name', e.target.value)}
                    />
                </Form.Item>
                <Form.Item label="Phone">
                    <Input
                        value={formData.phone}
                        onChange={e => handleChange('phone', e.target.value)}
                    />
                </Form.Item>
                <Form.Item label="Email">
                    <Input
                        value={formData.email}
                        onChange={e => handleChange('email', e.target.value)}
                    />
                </Form.Item>
                <Form.Item label="Number of Adults">
                    <InputNumber
                        min={0}
                        value={formData.adults}
                        onChange={handleAdultChange}
                        formatter={value => `${value} adults`}
                    />
                    <Button.Group>
                        <Button className='plus-button' onClick={() => handleAdultChange(formData.adults + 1)}>
                            <PlusOutlined className='plus-icon'/>
                        </Button>
                        <Button onClick={() => handleAdultChange(Math.max(0, formData.adults - 1))}>
                            <MinusOutlined />
                        </Button>
                    </Button.Group>
                </Form.Item>
                <Form.Item label="Number of Children">
                    <InputNumber
                        min={0}
                        value={formData.children}
                        onChange={handleChildrenChange}
                        formatter={value => `${value} children`}
                    />
                    <Button.Group>
                        <Button className='plus-button' onClick={() => handleChildrenChange(formData.children + 1)}>
                            <PlusOutlined className='plus-icon'/>
                        </Button>
                        <Button onClick={() => handleChildrenChange(Math.max(0, formData.children - 1))}>
                            <MinusOutlined />
                        </Button>
                    </Button.Group>
                </Form.Item>
                <Form.Item label="Event">
                    <Select
                        style={{ width: '100%' }}
                        value={formData.event}
                        onChange={value => handleChange('event', value)}
                    >
                        <Option value="birthday">Birthday</Option>
                        <Option value="wedding">Wedding</Option>
                        <Option value="conference">Conference</Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button className='book-btn' type="primary" htmlType="submit">
                        Book Now
                    </Button>
                <Button className="close-button" onClick={onClose}>Close</Button>   
                </Form.Item>
            </Form>
        </div>


    );
};

export default Booking;
