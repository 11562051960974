import React, { useState } from 'react';
import Booking from './Booking';

const Home = () => {
    const [showBooking, setShowBooking] = useState(false);

    const handleBookTickets = () => {
        setShowBooking(!showBooking);
    };

    const handleCloseBooking = () => {
        setShowBooking(false);
    };

    return (
        <div className='hero-container'>
            <div className='overlay' /> {/* Overlay always present */}
            <a href='https://luxanddecibels.in/'><img className='logo' src='https://luxanddecibels.in/assets/img/logo.png' alt="Logo"/></a>
            <div className={`hero-content ${showBooking ? 'hide-hero-content' : ''}`}>
                <h1>WELCOME TO CHANDRAGIRI LIGHT AND SOUND</h1>
                <button onClick={handleBookTickets}>Book Tickets</button>
            </div>
            {showBooking && <Booking onClose={handleCloseBooking} />}
        </div>
    );
};

export default Home;
